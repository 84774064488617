import React, { useState } from "react";
import LeadershipForm from "./leadershipform";
import axios from "axios";
import { serverLink } from "../../resources/url";
import swal from "sweetalert";
import Header from "../Header/header";
import { connect } from "react-redux";

const AddLeadership = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      const staffName = props.loginDetails?.staff_name || "Unknown";

      const dataToSend = {
        ...values,
        submittedBy: staffName,
      };

      Object.entries(dataToSend).forEach(([key, value]) => {
        if (key === "photo" && value) {
          formData.append("photo", value);
        } else if (key !== "image") {
          formData.append(key, value);
        }
      });

      const { data } = await axios.post(
        `${serverLink}leadership/add_leadership`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 30000,
          responseType: "json",
        }
      );

      handleApiResponse(data, resetForm);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApiResponse = (data, resetForm) => {
    const { status, message } = data;

    const responses = {
      success: {
        title: "SUCCESS",
        message: "LEADERSHIP ADDED SUCCESSFULLY",
        type: "success",
      },
      "name exist": {
        title: "Oops!",
        message: "Leadership with the same name and title already exist",
        type: "error",
      },
      default: {
        title: "Oops!",
        message:
          "Something went wrong adding the leadership. Please try again!",
        type: "error",
      },
    };

    // Check both status and message for success
    if (
      status === "success" ||
      message === "Leadership entry created successfully"
    ) {
      resetForm();
      showAlert("SUCCESS", "LEADERSHIP ADDED SUCCESSFULLY", "success");
    } else {
      const response = responses[message] || responses.default;
      showAlert(response.title, response.message, response.type);
    }
  };

  const handleError = (error) => {
    console.error("Leadership submission error:", error);

    if (error.response) {
      const errorMessage =
        error.response.data?.message || "Server error occurred";
      showAlert("Error", errorMessage, "error");
    } else if (error.request) {
      showAlert(
        "Error",
        "No response from server. Please check your connection.",
        "error"
      );
    } else if (error.code === "ECONNABORTED") {
      showAlert("Error", "Request timed out. Please try again.", "error");
    } else {
      showAlert(
        "Error",
        "Failed to add leadership. Please try again.",
        "error"
      );
    }
  };

  const showAlert = async (title, msg, type) => {
    await swal({
      title,
      text: msg,
      icon: type,
      button: "OK",
    });
    window.location.href = "#/leadership/list";
  };

  return (
    <>
      <Header isFaculty={false} />
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="toolbar" id="kt_toolbar">
          <div
            id="kt_toolbar_container"
            className="container-fluid d-flex flex-stack"
          >
            <div
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
              className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
            >
              <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                Leadership
                <span className="h-20px border-dark  border-start ms-3 mx-2" />
                Add Leadership (Board)
              </h1>
            </div>
          </div>
        </div>
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            {/* CONTENT START */}
            <div className="card mb-5 mb-xl-10">
              <div className="card-header">
                <div className="card-title">
                  <h3>ADD LEADERSHIP (BOARD)</h3>
                </div>
              </div>
              <LeadershipForm
                initialValues={{}}
                onSubmit={handleSubmit}
                isLoading={isLoading}
              />
            </div>
            {/* CONTENT END */}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.auth?.loginDetails || {}, // Add fallback
  };
};

// Add mapDispatchToProps to handle actions if needed
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLeadership);
