import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { stateFromHTML } from "draft-js-import-html";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  category: Yup.string().required("Category is required"),
  fullname: Yup.string().required("Full Name is required"),
  designation: Yup.string().required("Designation is required"),
  position: Yup.string().required("Position is required"),
  status: Yup.string().required("Status is required"),
  description: Yup.string().required("Description is required"),
  photo: Yup.mixed()
    .required("Image is required")
    .test("fileSize", "File size must be less than 2MB", (value) => {
      if (!value) return false;
      return value.size <= 2000000;
    })
    .test("fileType", "Only .jpg, .png and .jpeg formats allowed", (value) => {
      if (!value) return false;
      return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
    }),
});

const LeadershipForm = ({ initialValues, onSubmit, isLoading }) => {
  const [editorState, setEditorState] = useState(() => {
    const contentBlocks = stateFromHTML(initialValues.description || "");
    return EditorState.createWithContent(contentBlocks);
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      category: "",
      fullname: "",
      designation: "",
      position: "",
      status: "",
      description: "",
      photo: null,
      image: "",
    },
    validationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  });

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    formik.setFieldValue("description", html);
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      formik.setFieldValue("photo", file);

      const reader = new FileReader();
      reader.onload = (e) => {
        formik.setFieldValue("image", e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="card-body" style={{ textAlign: "left" }}>
        <div
          className="me-n7 pe-7"
          id="kt_modal_new_address_scroll text-left"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_new_address_header"
          data-kt-scroll-wrappers="#kt_modal_new_address_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="row mb-5">
            <div className="col-md-4 fv-row">
              <label className="required fs-5 fw-bold mb-2">Title</label>
              <input
                type="text"
                className={`form-control form-control-solid mb-5 ${
                  formik.touched.title && formik.errors.title
                    ? "is-invalid"
                    : ""
                }`}
                {...formik.getFieldProps("title")}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="invalid-feedback">{formik.errors.title}</div>
              )}
            </div>
            <div className="col-md-4 fv-row">
              <label className="required fs-5 fw-bold mb-2">Category</label>
              <select
                className={`form-control form-control-solid ${
                  formik.touched.category && formik.errors.category
                    ? "is-invalid"
                    : ""
                }`}
                {...formik.getFieldProps("category")}
              >
                <option value="">Select category</option>
                <option value="Board">Board</option>
                <option value="Management">Management</option>
                <option value="Senate">Senate</option>
              </select>
              {formik.touched.category && formik.errors.category && (
                <div className="invalid-feedback">{formik.errors.category}</div>
              )}
            </div>
            <div className="col-md-4 fv-row">
              <label className="required fs-5 fw-bold mb-2">Full Name</label>
              <input
                type="text"
                className="form-control form-control-solid  mb-5"
                name="fullname"
                id="fullname"
                onChange={formik.handleChange}
                value={formik.values.fullname}
                placeholder="Full Name"
              />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-6 fv-row">
              <label className="required fs-5 fw-bold mb-2">Designation</label>
              <input
                type="text"
                className={`form-control form-control-solid ${
                  formik.touched.designation && formik.errors.designation
                    ? "is-invalid"
                    : ""
                }`}
                {...formik.getFieldProps("designation")}
                placeholder="Designation"
              />
              {formik.touched.designation && formik.errors.designation && (
                <div className="invalid-feedback">
                  {formik.errors.designation}
                </div>
              )}
            </div>
            <div className="col-md-6 fv-row">
              <label className="required fs-5 fw-bold mb-2">Position</label>
              <select
                className={`form-control form-control-solid ${
                  formik.touched.position && formik.errors.position
                    ? "is-invalid"
                    : ""
                }`}
                {...formik.getFieldProps("position")}
              >
                <option value="">Select position</option>
                {[...Array(60)].map((_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
              {formik.touched.position && formik.errors.position && (
                <div className="invalid-feedback">{formik.errors.position}</div>
              )}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-6 fv-row">
              <label className="required fs-5 fw-bold mb-2">Image</label>
              <div
                className={`dropzone ${
                  formik.touched.photo && formik.errors.photo
                    ? "border-danger"
                    : ""
                }`}
                onClick={() => document.getElementById("photo").click()}
              >
                <div className="dz-message needsclick">
                  <i className="bi bi-image text-primary fs-3x" />
                  <div className="ms-4 col-md-9">
                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                      Click to upload.
                    </h3>
                    <span className="fs-7 fw-semibold text-gray-400 text-info">
                      {formik.values.photo
                        ? formik.values.photo.name
                        : "Only .jpg, .png, .jpeg are allowed"}
                    </span>
                  </div>
                  <div className="col-md-2">
                    {formik.values.image && (
                      <img
                        className="img-thumbnail"
                        width={120}
                        height={100}
                        alt="preview"
                        src={formik.values.image}
                      />
                    )}
                  </div>
                </div>
              </div>
              <input
                type="file"
                id="photo"
                accept="image/*"
                onChange={handleImageChange}
                hidden
              />
              {formik.touched.photo && formik.errors.photo && (
                <div className="text-danger mt-2">{formik.errors.photo}</div>
              )}
              <div className="row">
                <div className="col-md-6">
                  <span className="badge bg-danger mt-2">
                    Max of 2MB file is allowed!
                  </span>
                </div>
                <div className="col-md-6 pull-right text-right">
                  <span className="badge bg-primary mt-2 w-100">
                    Use 500 x 500 as image dimension.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 fv-row">
              <label className="required fs-5 fw-bold mb-2">Status</label>
              <select
                className={`form-control form-control-solid ${
                  formik.touched.status && formik.errors.status
                    ? "is-invalid"
                    : ""
                }`}
                {...formik.getFieldProps("status")}
              >
                <option value="">Select Status</option>
                <option value="1">Active</option>
                <option value="0">Draft</option>
              </select>
              {formik.touched.status && formik.errors.status && (
                <div className="invalid-feedback">{formik.errors.status}</div>
              )}
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-12 fv-row">
              <label className=" fs-5 fw-bold mb-2">Description</label>
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                wrapperClassName="form-control form-control-solid"
                editorClassName="form-control form-control-solid"
              />
              {formik.touched.description && formik.errors.description && (
                <div className="text-danger mt-2">
                  {formik.errors.description}
                </div>
              )}
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9"
          >
            {isLoading ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default LeadershipForm;
